<template>
  <svg
    width="18px"
    height="18px"
    class="move-icon"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/Document/ViewMode-Copy-4"
        transform="translate(-617.000000, -143.000000)"
      >
        <g class="ic_exit_to_app" transform="translate(614.000000, 140.000000)">
          <g class="Icon-24px">
            <polygon class="Shape" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M10.09,15.59 L11.5,17 L16.5,12 L11.5,7 L10.09,8.41 L12.67,11 L3,11 L3,13 L12.67,13 L10.09,15.59 L10.09,15.59 Z M19,3 L5,3 C3.89,3 3,3.9 3,5 L3,9 L5,9 L5,5 L19,5 L19,19 L5,19 L5,15 L3,15 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 L19,3 Z"
              class="color"
              fill="#363636"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.move-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
