<template>
  <div
    v-if="['/login', '/404', '/401'].includes(router.currentRoute.value.path)"
    class="app-one"
  >
    <router-view />
    <transition v-if="showNotification" name="slide">
      <Notification :data="notificationData" />
    </transition>
  </div>
  <div
    v-else-if="
      ['search', 'tasks', 'application', 'task-view', 'task-success'].includes(
        router.currentRoute.value.name?.toString() || ''
      )
    "
    class="new-layout"
  >
    <SideMenu :dark-mode="darkMode"></SideMenu>
    <router-view :key="router.currentRoute.value.fullPath" />
    <transition name="slide">
      <NetworkNotification
        :network-status="networkStatus"
        v-show="showNetworkNotification"
      />
    </transition>
    <transition name="slide" v-if="showNotification">
      <Notification :data="notificationData" />
    </transition>
  </div>
</template>
<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import SideMenu from '@/components/SideMenu.vue';
import './AppMain.scss';
import eventBus from '@/lib/event_bus';
import NetworkNotification from '@/components/misc/NetworkNotification.vue';
import { NotificationObj } from '@/interfaces/component_data';
import Notification from '@/components/notification/Notification.vue';
import { isEmpty } from '@/lib/helpers';
import router from '@/router';

const darkMode = ref(false);
const networkStatus = ref('online');
const showNetworkNotification = ref(false);
const showNotification = ref(false);
const notificationData = ref<NotificationObj[]>([]);

onBeforeMount(() => {
  eventBus.on('toggle-theme', switchTheme);
  eventBus.on('toggle-notification', toggleNotification);
  eventBus.on('remove-message', removeNotification);
  networkStatus.value = navigator.onLine ? 'online' : 'offline';
  showNetworkNotification.value = !navigator.onLine;
  window.addEventListener('online', () => {
    updateNetworkConnStatus(true);
  });
  window.addEventListener('offline', () => {
    updateNetworkConnStatus(false);
  });
  document.body.classList.add(`${darkMode.value ? 'darkTheme' : 'lightTheme'}`);
});

function toggleNotification(data: any) {
  if (!data) {
    showNotification.value = false;
    notificationData.value = [];
  } else if (data.show) {
    addNotification(data);
    showNotification.value = true;
  } else {
    notificationData.value = [];
    showNotification.value = false;
  }
}

function switchTheme() {
  darkMode.value = !darkMode.value;
  document.body.classList.remove(
    `${!darkMode.value ? 'darkTheme' : 'lightTheme'}`
  );
  document.body.classList.add(`${darkMode.value ? 'darkTheme' : 'lightTheme'}`);
}

function addNotification(data: any) {
  notificationData.value = [...notificationData.value, data];
}

function removeNotification(data: any) {
  if (!isEmpty(notificationData.value) && notificationData.value[data.index]) {
    notificationData.value.splice(data.index, 1);
  }
}

function updateNetworkConnStatus(connected: boolean) {
  if (connected) {
    networkStatus.value = 'online';
    if (showNetworkNotification.value) {
      setTimeout(() => {
        showNetworkNotification.value = false;
      }, 2000);
    }
  } else {
    networkStatus.value = 'offline';
    showNetworkNotification.value = true;
  }
}

onBeforeUnmount(() => {
  eventBus.off('toggle-theme', switchTheme);
  eventBus.off('toggle-notification', toggleNotification);
  eventBus.off('remove-message', removeNotification);
});
</script>
