import { message } from '@/components/notification/Message';
import { deleteLocalKey } from './localStore';
import { APPLICATION_TO_LEAD_ID_MAP, USER_PROFILE } from './localKeys';
import router from '@/router/index';
import { getApplicationData, logout } from '@/api';
import { responseHelper, defaultCatch } from '@/api/utils';
import trimStart from 'lodash.trimstart';

export const updateCustomerDetails = (
  { commit }: any,
  { customerDetails }: any
): void => {
  commit('UPDATE_CUSTOMER_DETAILS', customerDetails);
};

export const doLogout = ({ commit }: any, payload: any): void => {
  if (router.currentRoute.value.name !== 'login') {
    const redirectUrl: string = trimStart(window.location.hash, '#');
    if (payload.callApi) {
      logout()
        .then(
          responseHelper(
            () => {
              deleteLocalKey(USER_PROFILE);
              deleteLocalKey(APPLICATION_TO_LEAD_ID_MAP);
              commit('UPDATE_CUSTOMER_DETAILS', {});
              commit('UPDATE_USER_PROFILE', {});
              commit('SET_CURRENT_PARTNER', '');
              commit('UPDATE_CURRENT_LEAD_ID', null);
              message.info('You have been logged out!');
              router.replace({
                path: '/login',
                query: { redirect: redirectUrl },
              });
            },
            (errorCode, errorMessage) => {
              message.error(errorMessage || 'Error logging out');
            }
          )
        )
        .catch((err) => {
          deleteLocalKey(USER_PROFILE);
          deleteLocalKey(APPLICATION_TO_LEAD_ID_MAP);
          commit('UPDATE_CUSTOMER_DETAILS', {});
          commit('UPDATE_USER_PROFILE', {});
          commit('SET_CURRENT_PARTNER', '');
          commit('UPDATE_CURRENT_LEAD_ID', null);
          message.info('You have been logged out!');
          router.replace({ path: '/login', query: { redirect: redirectUrl } });
          defaultCatch(err);
        });
    } else {
      deleteLocalKey(USER_PROFILE);
      deleteLocalKey(APPLICATION_TO_LEAD_ID_MAP);
      commit('UPDATE_CUSTOMER_DETAILS', {});
      commit('UPDATE_USER_PROFILE', {});
      commit('SET_CURRENT_PARTNER', '');
      commit('UPDATE_CURRENT_LEAD_ID', null);
      message.info('You have been logged out!');
      router.replace({ path: '/login', query: { redirect: redirectUrl } });
    }
  }
};

export const retrieveApplication = (
  { commit, state }: any,
  userInfo: any
): void => {
  if (userInfo.applicationId) {
    getApplicationData(userInfo.applicationId, state.sections)
      .then(
        responseHelper(
          (response: any) => {
            console.log(response);
            commit('UPDATE_CUSTOMER_DETAILS', response);
          },
          (errorCode, errorMessage) => {
            message.error(
              errorMessage || 'Something went wrong. Contact MoneyTap'
            );
          }
        )
      )
      .catch(defaultCatch);
  } else {
    message.warn('Application was not found');
  }
};

export const triggerApiComplete = ({ commit }: any): void => {
  commit('UPDATE_LOADER_DATA', {
    spinning: false,
    message: 'Loading...',
    fullScreen: false,
  });
};

export const triggerApiStart = ({ commit }: any, payload: any): void => {
  commit('UPDATE_LOADER_DATA', {
    spinning: true,
    message: payload.message || 'Loading...',
    fullScreen: payload.fullScreen || true,
  });
};
