import eventBus from '@/lib/event_bus';
export class Message {
  public error(text: string) {
    eventBus.emit('toggle-notification', {
      show: true,
      type: 'error',
      message: text,
    });
  }
  public success(text: string) {
    eventBus.emit('toggle-notification', {
      show: true,
      type: 'success',
      message: text,
    });
  }
  public warning(text: string) {
    eventBus.emit('toggle-notification', {
      show: true,
      type: 'warning',
      message: text,
    });
  }
  public info(text: string) {
    eventBus.emit('toggle-notification', {
      show: true,
      type: 'info',
      message: text,
    });
  }
  public warn(text: string) {
    this.warning(text);
  }
}

export const message = new Message();
