import store from '@/store/index';
import { createRouter, createWebHashHistory } from 'vue-router';
import { get } from '@/lib/helpers';
import { getLocalKey } from '@/store/localStore';
import { USER_PROFILE } from '@/store/localKeys';
import { updatePartnerConfig } from '@/partner_config';
import { retrieveUserFromJwt, shouldHideAllActions } from '@/lib/utils';
import { isEmpty } from 'lodash';
import { getLeadDetails } from '@/api';
import { message } from '@/components/notification/Message';
import { defaultCatch, responseHelper } from '@/api/utils';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/tasks',
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
  },
  {
    path: '/search',
    name: 'search',
    meta: { requiresAuth: true },
    beforeEnter(to: any, from: any, next: any) {
      store.commit('UPDATE_LEAD_DETAILS', {});
      next();
    },
    component: () =>
      import(
        /* webpackChunkName:  "global-search" */ '@/views/GlobalSearch.vue'
      ),
  },
  {
    path: '/tasks',
    name: 'tasks',
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName:  "task-search" */ '@/views/TasksSearch.vue'),
  },
  {
    path: '/a/:applicationId/:leadId?/:taskId?',
    name: 'application',
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: 'application' */ '@/views/TaskView.vue'),
  },
  {
    path: '/t/:leadId/:taskId?/:taskType?',
    name: 'task-view',
    meta: { requiresAuth: true },
    props: true,
    beforeEnter(to: any, from: any, next: any) {
      const leadDetail = get(store.state, 'leadDetail', {});
      console.log(store.state);
      if (leadDetail && leadDetail.id === to.params.leadId) {
        return next();
      }
      store.dispatch('triggerApiStart', { message: 'Getting lead details...' });
      getLeadDetails(to.params.leadId)
        .then(
          responseHelper(
            (response: any) => {
              console.log(response);
              store.commit('UPDATE_LEAD_DETAILS', response);
              updatePartnerConfig({
                currentLineType: get(
                  response,
                  'leadDetail.productType',
                  'DEFAULT'
                ),
                currentPartner: get(
                  response,
                  'leadDetail.partnerCode',
                  'DEFAULT'
                ),
              });
              next();
            },
            () => {
              message.error('Failed to fetch application..!');
            }
          )
        )
        .catch(defaultCatch);
    },
    component: () =>
      import(/* webpackChunkName:  "task-view" */ '@/views/TaskView.vue'),
  },
  {
    path: '/tc/:leadId/:taskId?/:taskType?',
    name: 'task-success',
    beforeEnter(to: any, from: any, next: any) {
      const leadDetail = get(store.state, 'leadDetail', {});
      console.log(store.state);
      if (leadDetail && leadDetail.id === to.params.leadId) {
        return next();
      }
      store.dispatch('triggerApiStart', { message: 'Getting lead details...' });
      getLeadDetails(to.params.leadId)
        .then(
          responseHelper(
            (response: any) => {
              console.log(response);
              store.commit('UPDATE_LEAD_DETAILS', response);
              updatePartnerConfig({
                currentLineType: get(
                  response,
                  'leadDetail.productType',
                  'DEFAULT'
                ),
                currentPartner: get(
                  response,
                  'leadDetail.partnerCode',
                  'DEFAULT'
                ),
              });
              next();
            },
            () => {
              message.error('Failed to fetch application..!');
            }
          )
        )
        .catch(defaultCatch);
    },
    component: () => import('@/views/TaskComplete.vue'),
  },
];

const router = createRouter({ history: createWebHashHistory(), routes });

router.beforeEach(async (to: any, from: any, next: any) => {
  let requiresAuth: boolean = true;
  requiresAuth = to.matched.some((record: any) => record.meta.requiresAuth);
  let accessToken = get(store, 'state.userProfile.access_token', '');
  if (!accessToken) {
    let userProfile = getLocalKey(USER_PROFILE);
    accessToken = userProfile && userProfile.access_token;
    try {
      const userInfo = await retrieveUserFromJwt(userProfile.access_token);
      if (!isEmpty(userInfo)) {
        userProfile = {
          ...userProfile,
          ...userInfo,
        };
        const readonlyAccess = shouldHideAllActions(userInfo);
        store.commit('UPDATE_READONLY_ACCESS', readonlyAccess);
      }
    } catch {
      console.log('Error while retrieve info from jwt');
    } finally {
      store.commit('UPDATE_USER_PROFILE', userProfile);
    }
  }
  if (requiresAuth && !accessToken && !to.fullPath.includes('/login')) {
    next({
      name: 'login',
      replace: true,
      query: { redirect: to.fullPath, ...to.query },
    });
    return;
  } else {
    let isDelegated = false;
    for (let matched = to.matched || [], i = matched.length; i--; ) {
      const route = matched[i];
      if (route.beforeEnter) {
        isDelegated = true;
        // route.beforeEnter(to, from, next);
        next();
        return;
      }
    }
    if (!isDelegated) {
      next();
      return;
    }
  }
});

export default router;
