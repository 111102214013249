import { isEmpty } from '@/lib/helpers';
import store from '@/store';

const configurations: Record<string, PartnerConfig> = {};
const partnerFileMapping: Record<string, string> = {
  RBL: 'rbl',
  FULLERTON: 'fullerton',
  INCRED: 'incred',
  MUSKMELON: 'muskmelon',
  TAPSTART: 'tapstart',
  HDBFS: 'hdbfs',
  CREDITSAISON: 'creditsaison',
  LENDBOX: 'lendbox',
  CHOLAMANDALAM: 'cholamandalam',
  DEFAULT: 'DEFAULT',
};

const lineTypeFileMapping: Record<string, string> = {
  FAST_CASH: 'fast_cash',
  CASH_LINE: 'cash_line',
  DEFAULT: 'DEFAULT',
};

interface PartnerConfigObj {
  currentPartner: string;
  currentLineType: string;
}

class PartnerConfig {
  private _partnerConstants: any;

  constructor(partnerConstants: any) {
    this._partnerConstants = partnerConstants;
  }

  public getAllPartnerConstants() {
    return this._partnerConstants.default;
  }

  // TODO: Revisit to refactor
  public getConstantByName(constantName: string, defaultValue: any) {
    return this._partnerConstants.default[constantName] || defaultValue;
  }
}

export function updatePartnerConfig(partnerOptions: PartnerConfigObj) {
  require.ensure([], (require) => {
    const { currentLineType = '', currentPartner = '' } = partnerOptions;
    const partner = partnerFileMapping[currentPartner || 'DEFAULT'];
    const lineType = lineTypeFileMapping[currentLineType || 'DEFAULT'];
    if (partner && lineType) {
      if (isEmpty(configurations[`${currentPartner}_${currentLineType}`])) {
        let config = new PartnerConfig(
          require(`./${partner}/${lineType}/partner_constants`)
        );
        if (!config) {
          config = new PartnerConfig(
            require(`./DEFAULT/DEFAULT/partner_constants`)
          );
        }
        configurations[`${currentPartner}_${currentLineType}`] = config;
        store.commit('UPDATE_CURRENT_CONFIG', config);
      } else {
        store.commit(
          'UPDATE_CURRENT_CONFIG',
          configurations[`${currentPartner}_${currentLineType}`]
        );
      }
    }
  });
}

function getCurrentConfig(partnerOptions: PartnerConfigObj) {
  const { currentPartner = '', currentLineType = '' } = partnerOptions;
  if (currentPartner && currentLineType) {
    const currentConfig: PartnerConfig | null =
      configurations[`${currentPartner}_${currentLineType}`] || null;
    if (!currentConfig) {
      updatePartnerConfig(partnerOptions);
    }
    return currentConfig || null;
  }
  return null;
}

// TODO: Store partnerOptions in store and read from the store
export function getAllPartnerConstants(partnerOptions: PartnerConfigObj) {
  const currentConfig: PartnerConfig | null = getCurrentConfig(partnerOptions);
  if (currentConfig) {
    return currentConfig.getAllPartnerConstants();
  }
  return {};
}

export function getConstantByName(
  partnerOptions: PartnerConfigObj,
  constantName: string,
  defaultValue: any
) {
  const currentConfig: PartnerConfig | null = getCurrentConfig(partnerOptions);
  if (currentConfig) {
    return currentConfig.getConstantByName(constantName, defaultValue);
  }
  return defaultValue;
}

export function getTaskDetailConfig(
  partnerOptions: PartnerConfigObj,
  constantName: string,
  taskName: string
) {
  const currentConfig: PartnerConfig | null = getCurrentConfig(partnerOptions);
  console.log(currentConfig);
  if (currentConfig) {
    return currentConfig.getConstantByName(constantName, () => ({}))(taskName);
  }
  return {};
}

export function getHiddenMenuConfig(
  partnerOptions: PartnerConfigObj,
  constantName: string,
  taskName: string
) {
  const currentConfig: PartnerConfig | null = getCurrentConfig(partnerOptions);
  if (currentConfig) {
    return currentConfig.getConstantByName(constantName, () => [])(taskName);
  }
  return [];
}

export function getActionMenuConfig(
  partnerOptions: PartnerConfigObj,
  constantName: string,
  taskName: string
) {
  const currentConfig: PartnerConfig | null = getCurrentConfig(partnerOptions);
  if (currentConfig) {
    return currentConfig.getConstantByName(constantName, () => [])(taskName);
  }
  return [];
}
