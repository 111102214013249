<template>
  <svg
    width="17px"
    class="mail-icon"
    height="13px"
    viewBox="0 0 17 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/CustomerDetails"
        transform="translate(-122.000000, -402.000000)"
      >
        <g class="Group-3" transform="translate(75.000000, 0.000000)">
          <g
            class="mail_outline-24px"
            transform="translate(45.000000, 398.000000)"
          >
            <polygon class="Path" points="0 0 21 0 21 21 0 21"></polygon>
            <path
              d="M17.3,4 L3.7,4 C2.765,4 2.0085,4.73125 2.0085,5.625 L2,15.375 C2,16.26875 2.765,17 3.7,17 L17.3,17 C18.235,17 19,16.26875 19,15.375 L19,5.625 C19,4.73125 18.235,4 17.3,4 Z M17.3,15.375 L3.7,15.375 L3.7,7.25 L10.5,11.3125 L17.3,7.25 L17.3,15.375 Z M10.5,9.6875 L3.7,5.625 L17.3,5.625 L10.5,9.6875 Z"
              class="Shape color"
              fill="#363636"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.mail-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
