<template>
  <div class="networkNotification" :class="networkStatus">
    <p>{{ displayText }}</p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    networkStatus: string;
  }>(),
  {
    networkStatus: 'online',
  }
);

const displayText = computed(() => {
  return props.networkStatus === 'online' ? 'Back online.' : `You're offline.`;
});
</script>

<style lang="scss" scoped>
.networkNotification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: grey;
  color: white;
  z-index: 1000;

  p {
    font-size: var(--font_md);
    margin: 0;
    font-weight: bold;
  }

  &.offline {
    background-color: #d32f2f;
  }

  &.online {
    background-color: #4caf50;
  }
}
</style>
