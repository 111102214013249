import { DropDownData, TaskSearchPayload } from './../interfaces/app_config';
import { sectionMapping } from '@/lib/consts';
import { INPUT_DATE_REGEX } from './consts';
import {
  camelCaseToTitleCase,
  get,
  isEmpty,
  snakeCaseToTitleCase,
} from './helpers';
import store from '@/store';
import { ComputedRef, computed } from 'vue';

export function getSectionNames(sections: string[]) {
  const alternateSectionNames = [];
  for (const section of sections) {
    if (sectionMapping[section]) {
      alternateSectionNames.push(sectionMapping[section]);
    }
  }
  return alternateSectionNames;
}

export function calculateAge(dateOfBirth: string) {
  if (dateOfBirth) {
    const dateOfBirthObj = dateOfBirth.match(INPUT_DATE_REGEX);
    if (dateOfBirthObj) {
      const dateObj = {
        year: dateOfBirthObj[3],
        month: dateOfBirthObj[2],
        day: dateOfBirthObj[1],
      };
      const formattedDate = `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
      const differenceInMs = Date.now() - new Date(formattedDate).getTime();
      const ageDate = new Date(differenceInMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    } else {
      return 0;
    }
  }
  return 0;
}

export function restrictInputValue(rule: string, evt: any) {
  switch (rule) {
    case 'alpha':
      return isAlpha(evt);
    case 'digits':
      return isNumberKey(evt);
    case 'alphaNumeric':
      return isAlphaNumeric(evt);
    case 'alphaNumericSpace':
      return isAlphaNumericSpace(evt);
    case 'queryName':
      return isQueryName(evt);
    default:
      return true;
  }
}

function isNumberKey(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if (![48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(charCode)) {
    evt.preventDefault();
    return false;
  }
  return true;
}

function isAlpha(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
}

function isAlphaNumeric(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if (
    (charCode > 64 && charCode < 91) ||
    (charCode > 96 && charCode < 123) ||
    (charCode > 47 && charCode < 58)
  ) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
}

function isAlphaNumericSpace(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if (
    (charCode > 64 && charCode < 91) ||
    (charCode > 96 && charCode < 123) ||
    [32, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(charCode)
  ) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
}

function isQueryName(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if (
    (charCode > 64 && charCode < 91) ||
    (charCode > 96 && charCode < 123) ||
    [32, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 45].includes(charCode)
  ) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
}

export function isMathcingArrays(array1: [], array2: []) {
  if (
    (array1.length !== 0 || array2.length !== 0) &&
    array1.length === array2.length
  ) {
    array1.sort();
    array2.sort();
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
  }
  return true;
}

export function sortList(
  listToSort: any[],
  key: string,
  dataType: string = 'date',
  direction: string = 'desc'
) {
  if (dataType === 'date') {
    return listToSort.sort((firstElem: any, secondElem: any) => {
      if (direction === 'desc') {
        return (
          new Date(secondElem[key]).getTime() -
          new Date(firstElem[key]).getTime()
        );
      }
      return (
        new Date(firstElem[key]).getTime() - new Date(secondElem[key]).getTime()
      );
    });
  }
  if (direction === 'desc') {
    return listToSort.sort(
      (firstElem: any, secondElem: any) => secondElem[key] - firstElem[key]
    );
  }
  return listToSort.sort(
    (firstElem: any, secondElem: any) => firstElem[key] - secondElem[key]
  );
}

export function deDuplicateArray(arr: any[]) {
  return arr.filter((item, pos) => arr.indexOf(item) === pos);
}

export function copyToClipboard(text: string) {
  if ((window as any).clipboardData && (window as any).clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return (window as any).clipboardData.setData('Text', text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

export function isValidDate(date: string) {
  const dateArray = date.match(INPUT_DATE_REGEX);
  if (dateArray) {
    const year = Number(dateArray[3]);
    const month = Number(dateArray[2]) - 1;
    const day = Number(dateArray[1]);

    const refDate = new Date(year, month, day);

    const yearMatches = refDate.getFullYear() === year;
    const monthMatches = refDate.getMonth() === month;
    const dayMatches = refDate.getDate() === day;

    return yearMatches && monthMatches && dayMatches;
  } else {
    return false;
  }
}

export const prettifyJson = (json: string) => {
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,
    (match) => {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    }
  );
};

export const limitChars = (str: string, limit = 65, slice = 10) => {
  if (str && str.length > limit) {
    return `${str.slice(0, limit - slice)}...`;
  }
  return str;
};

export async function retrieveUserFromJwt(jwt: any) {
  if (!jwt) {
    return {};
  }
  try {
    const VueJwtDecode = await import('vue-jwt-decode');
    const decodeJwt = VueJwtDecode.default.decode(jwt);
    return {
      ...(decodeJwt.org && { organisation: decodeJwt.org }),
      ...(decodeJwt.user_name && { userName: decodeJwt.user_name }),
      ...(decodeJwt.org && { organisation: decodeJwt.org }),
      ...(decodeJwt.exp && { expiryDate: decodeJwt.exp }),
      ...(decodeJwt.authorities && { authorities: decodeJwt.authorities }),
    };
  } catch {
    console.log('Log nothing');
    return {};
  }
}

export const isValidAuthentication = (userProfile: any) => {
  if (userProfile && userProfile.access_token && userProfile.expiry) {
    const expiryDate = new Date(userProfile.expiry * 1000).getTime();
    if (expiryDate < new Date().getTime()) {
      return false;
    }
    return true;
  }
  return false;
};

export const isFiltersEmpty = (mainActiveFilters: {
  [key: string]: DropDownData[];
}) => {
  if (mainActiveFilters) {
    for (const key in mainActiveFilters) {
      if (Object.prototype.hasOwnProperty.call(mainActiveFilters, key)) {
        const element = mainActiveFilters[key];
        if (!isEmpty(element)) {
          return false;
        }
      }
    }
  }
  return true;
};

export const getSearchParams = (filters: any) => {
  const payload: TaskSearchPayload = {
    processKeys: [],
    taskNames: [],
    partnerCodes: [],
    generations: [],
    financeReportRequirements: [],
    productSubTypes: [],
    tags: [],
    appSourceTypes: [],
    appSources: [],
  };
  if (!isFiltersEmpty(filters)) {
    if (filters && filters.partners && filters.partners.length > 0) {
      payload.partnerCodes = filters.partners.reduce((pv: any, cv: any) => {
        pv.push(cv.key);
        return pv;
      }, []);
    }
    if (
      filters &&
      filters.productVariantTypes &&
      filters.productVariantTypes.length > 0
    ) {
      payload.productVariant = filters.productVariantTypes[0].key;
    }
    if (filters && filters.processKeys && filters.processKeys.length > 0) {
      payload.processKeys = filters.processKeys.reduce((pv: any, cv: any) => {
        pv.push(cv.key);
        return pv;
      }, []);
    }
    if (filters && filters.taskTypes && filters.taskTypes.length > 0) {
      payload.taskNames = filters.taskTypes.reduce((pv: any, cv: any) => {
        pv.push(cv.key);
        return pv;
      }, []);
    }
    if (filters && filters.generations && filters.generations.length > 0) {
      payload.generations = filters.generations.reduce((pv: any, cv: any) => {
        pv.push(cv.key);
        return pv;
      }, []);
    }
    if (
      filters &&
      filters.financeReportRequirements &&
      filters.financeReportRequirements.length > 0
    ) {
      payload.financeReportRequirements =
        filters.financeReportRequirements.reduce((pv: any, cv: any) => {
          pv.push(cv.key);
          return pv;
        }, []);
    }
    if (
      filters &&
      filters.productSubTypes &&
      filters.productSubTypes.length > 0
    ) {
      payload.productSubTypes = filters.productSubTypes.reduce(
        (pv: any, cv: any) => {
          pv.push(cv.key);
          return pv;
        },
        []
      );
    }
    if (filters && filters.tags && filters.tags.length > 0) {
      payload.tags = filters.tags.reduce((pv: any, cv: any) => {
        pv.push(cv.key);
        return pv;
      }, []);
    }
    if (
      filters &&
      filters.appSourceTypes &&
      filters.appSourceTypes.length > 0
    ) {
      payload.appSourceTypes = filters.appSourceTypes.reduce(
        (pv: any, cv: any) => {
          pv.push(cv.key);
          return pv;
        },
        []
      );
    }
    if (filters && filters.appSources && filters.appSources.length > 0) {
      payload.appSources = filters.appSources.reduce((pv: any, cv: any) => {
        pv.push(cv.key);
        return pv;
      }, []);
    }
    if (
      filters &&
      filters.fulfilmentApplicableTypes &&
      filters.fulfilmentApplicableTypes.length > 0
    ) {
      payload.fulfilmentApplicable = filters.fulfilmentApplicableTypes[0].key;
    }
    if (filters && filters.savedQueries && filters.savedQueries.length > 0) {
      filters.savedQueries.forEach((element: any) => {
        const temp = getSearchParams(element.filters);
        payload.processKeys = deDuplicateArray([
          ...payload.processKeys,
          ...temp.processKeys,
        ]);
        payload.taskNames = deDuplicateArray([
          ...payload.taskNames,
          ...temp.taskNames,
        ]);
        payload.generations = deDuplicateArray([
          ...(payload.generations || []),
          ...(temp.generations || []),
        ]);
        payload.financeReportRequirements = deDuplicateArray([
          ...(payload.financeReportRequirements || []),
          ...(temp.financeReportRequirements || []),
        ]);
        payload.productSubTypes = deDuplicateArray([
          ...(payload.productSubTypes || []),
          ...(temp.productSubTypes || []),
        ]);
        payload.tags = deDuplicateArray([
          ...(payload.tags || []),
          ...(temp.tags || []),
        ]);
        payload.appSourceTypes = deDuplicateArray([
          ...(payload.appSourceTypes || []),
          ...(temp.appSourceTypes || []),
        ]);
        payload.appSources = deDuplicateArray([
          ...(payload.appSources || []),
          ...(temp.appSources || []),
        ]);
        payload.partnerCodes = deDuplicateArray([
          ...(payload.partnerCodes || []),
          ...(temp.partnerCodes || []),
        ]);
        if (temp.productVariant) {
          payload.productVariant = temp.productVariant;
        }
        if (temp.fulfilmentApplicable) {
          payload.fulfilmentApplicable = temp.fulfilmentApplicable;
        }
      });
    }
  }
  return payload;
};

export const getPayloadKeysForDetails = (
  requirement: string,
  config: any,
  data: any,
  allowEmpty?: any
) => {
  const fields = config[requirement] && config[requirement].jsonStructure;
  if (fields && fields.length > 0) {
    const keys: any = {};
    for (const field of fields) {
      if (data[field.key] || allowEmpty) {
        keys[field.key] = data[field.key];
      }
    }
    return { requirement, value: keys };
  } else {
    return {
      requirement,
      value: data,
    };
  }
};

export const shouldHideAllActions = (securityContext: any) => {
  const roles = securityContext.authorities;
  return roles.includes('SEARCH_ALL') && roles.length === 1;
};

export async function decodeJsonWebToken(jwt: any) {
  try {
    const VueJwtDecode = await import('vue-jwt-decode');
    const decodedJWT = VueJwtDecode.default.decode(jwt);
    return decodedJWT;
  } catch (err) {
    console.log('Error decoding JWT: ', err);
    return '';
  }
}

export function toCurrencyFormat(amount: string) {
  if (amount || !isNaN(parseFloat(amount))) {
    return `₹ ${parseFloat(amount).toLocaleString('en-IN')}`;
  }
  return amount;
}

export function toTimeFormat(date: string) {
  if (date) {
    return new Date(date).toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
    });
  }
  return date;
}
export function toDateFormat(date: string) {
  if (date) {
    return new Date(date).toLocaleDateString('en-IN', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }
  return date;
}

export function removeUnderscore(str: string) {
  if (str && typeof str === 'string') {
    return str.replace(new RegExp('_', 'g'), ' ');
  }
  return str;
}

export function toNormalCase(str: string) {
  if (str) {
    const result = str.replace(/([A-Z])/g, ' $1');
    return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
  }
  return str;
}
export function toDecimalFormat(value: any) {
  if (typeof value === 'number') {
    return value.toFixed(2);
  }
  return value;
}
export function fromCamelCase(str: string) {
  if (str) {
    return camelCaseToTitleCase(str);
  }
  return str || '-';
}
export function fromSnakeCase(str: string) {
  if (str) {
    return snakeCaseToTitleCase(str);
  }
  return str || '-';
}

export function StoreGetter(getVal: string): ComputedRef<any> {
  return computed(() => get(store, `getters.${getVal}`, {}));
}

export function StoreState(getVal: string): ComputedRef<any> {
  return computed(() => get(store, `state.${getVal}`, {}));
}

export function StoreMutation(action: string, data: any) {
  store.commit(action, data);
}

export function StoreAction(action: string, data: any) {
  store.dispatch(action, data);
}
