import { createApp } from 'vue';
import { notification } from 'ant-design-vue';
import App from '@/App.vue';
import router from '@/router';
import store from './store';
import './registerServiceWorker';
import './styles/index.scss';
import 'ant-design-vue/dist/reset.css';
import Antd from 'ant-design-vue';

const app = createApp(App as any)
  .use(router)
  .use(store);
app.use(Antd).mount('#app');

app.config.globalProperties.$notification = notification;
