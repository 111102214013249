<template>
  <svg
    width="19px"
    height="22px"
    class="copy-icon"
    viewBox="0 0 19 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/Document/ViewMode-Copy-4"
        transform="translate(-553.000000, -141.000000)"
      >
        <g
          class="ic_content_copy"
          transform="translate(552.000000, 141.000000)"
        >
          <g class="Icon-24px">
            <polygon class="Shape" points="0 0 22 0 22 22 0 22"></polygon>
            <path
              d="M14.6666667,0.916666667 L3.66666667,0.916666667 C2.65833333,0.916666667 1.83333333,1.74166667 1.83333333,2.75 L1.83333333,15.5833333 L3.66666667,15.5833333 L3.66666667,2.75 L14.6666667,2.75 L14.6666667,0.916666667 L14.6666667,0.916666667 Z M17.4166667,4.58333333 L7.33333333,4.58333333 C6.325,4.58333333 5.5,5.40833333 5.5,6.41666667 L5.5,19.25 C5.5,20.2583333 6.325,21.0833333 7.33333333,21.0833333 L17.4166667,21.0833333 C18.425,21.0833333 19.25,20.2583333 19.25,19.25 L19.25,6.41666667 C19.25,5.40833333 18.425,4.58333333 17.4166667,4.58333333 L17.4166667,4.58333333 Z M17.4166667,19.25 L7.33333333,19.25 L7.33333333,6.41666667 L17.4166667,6.41666667 L17.4166667,19.25 L17.4166667,19.25 Z"
              class="color"
              fill="#363636"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.copy-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
