import { get } from '@/lib/helpers';
import store from '@/store';
import { USER_PROFILE } from '@/store/localKeys';
import { getLocalKey } from '@/store/localStore';
import { message } from '@/components/notification/Message';

export const responseHelper =
  (
    onSuccess: (data: any) => void,
    onFailure: (data: any, response: any) => void
  ) =>
  (response: any) => {
    if (response.ok && response.status >= 200 && response.status < 400) {
      const { data } = response;
      onSuccess(data);
    } else {
      const { data = {} } = response;
      onFailure(data, response);
    }
    return response;
  };

// Default catch function when API fails
export const defaultCatch = (err: any) => {
  console.log('Error occured while processing request', err);
  const status = err.status;
  const errorMessage: Record<string | number, string> = {
    500: 'Something went wrong at our end. Please contact MoneyTap',
    401: 'You have to be logged in to gain access',
    403: "You don't have sufficient privileges for the requested operation",
    404: "Oops! We didn't find what you were looking for",
    502: 'Server down. Please contact MoneyTap for further assistance',
    503: 'Sevice is unavailable. Contact MoneyTap for further assistance',
    504: 'Request took too long. Contact MoneyTap for further assistance',
    405: 'Method not allowed. Contact MoneyTap for further assistance',
    400: get(
      err,
      'data.error_description',
      'One of our developers screwed up. Contact Moneytap for further assistance'
    ),
    477: get(
      err,
      'data.error.message',
      'Something went wrong at our end. Please contact MoneyTap'
    ),
    defaultMessage: 'Something went wrong. Contact MoneyTap',
  };
  message.error(
    errorMessage[status || 'defaultMessage'] || errorMessage.defaultMessage
  );
};

export const fetchHandler = (response: any) => {
  const defaultResp = {
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
  };
  if (response.ok) {
    return response
      .json()
      .then((data: any) => {
        // the status was ok and there is a json body
        return Promise.resolve({ data, rawResp: response, ...defaultResp });
      })
      .catch((err: any) => {
        // the status was ok but there is no json body
        return Promise.resolve({
          data: err,
          rawResp: response,
          ...defaultResp,
        });
      });
  } else {
    return response
      .json()
      .catch((err: any) => {
        // the status was not ok and there is no json body
        return Promise.resolve({
          rawResp: response,
          data: err,
          ...defaultResp,
        });
      })
      .then((json: any) => {
        // the status was not ok but there is a json body
        return Promise.resolve({
          rawResp: response,
          data: json,
          ...defaultResp,
        });
      });
  }
};

export const addAuthHeader = (config: any) => {
  let accessToken = get(store, 'state.userProfile.access_token', '');
  let tokenType = get(store, 'state.userProfile.token_type', '');
  if (!accessToken) {
    const userProfile = getLocalKey(USER_PROFILE);
    accessToken = userProfile && userProfile.access_token;
    tokenType = userProfile && userProfile.token_type;
  }
  if (accessToken && tokenType) {
    config.headers.Authorization = `${
      tokenType === 'bearer' ? 'Bearer' : tokenType
    } ${accessToken}`;
  }
  return config;
};

export const getHeaders = (defaultHeaders: object) => {
  let headers: object = defaultHeaders;
  let accessToken = get(store, 'state.userProfile.access_token', '');
  let tokenType = get(store, 'state.userProfile.token_type', '');
  if (!accessToken) {
    const userProfile = getLocalKey(USER_PROFILE);
    accessToken = userProfile && userProfile.access_token;
    tokenType = userProfile && userProfile.token_type;
  }
  if (accessToken && tokenType) {
    headers = {
      ...headers,
      Authorization: `${
        tokenType === 'bearer' ? 'Bearer' : tokenType
      } ${accessToken}`,
    };
  }
  return headers;
};
