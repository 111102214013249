import { stringify } from 'qs';
import restApiClient from './fetch_helper';
import { isEmpty } from '@/lib/helpers';
import { TaskExecutePayload, TaskSearchPayload } from '@/interfaces/app_config';
import { fetchHandler, getHeaders } from './utils';
import store from '@/store';

/**
 * POST Login user to banker portal
 * @param username
 * @param password
 * @returns {Promise}
 */
export function login({
  username,
  password,
  authType = 'EMAIL_OTP',
}: {
  username: string;
  password: string;
  authType: string;
}): Promise<any> {
  const formData = `auth_version=2&grant_type=password&username=${username}&scope=read+write&password=${password}&auth_type=${authType}`;
  const authHeader = btoa(
    `${process.env.VUE_APP_CLIENT_ID}:${process.env.VUE_APP_CLIENT_SECRET}`
  );

  return fetch(`${process.env.VUE_APP_AUTH_BASE_URL}/oauth/token`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${authHeader}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formData,
  });
}

/**
 * POST Send email otp to username
 * @returns {Promise}
 */
export function sendEmailOtp(username: string): Promise<any> {
  return fetch(`${process.env.VUE_APP_AUTH_BASE_URL}/v1/email-otp/send`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: username }),
  });
}

/**
 * POST Logs out user from banker portal
 * @returns {Promise}
 */
export function logout(): Promise<any> {
  return restApiClient.post('/rest-api/logout');
}

/**
 * GET Get task list
 * @param {TaskSearchPayload} payload
 * @returns {Promise}
 */
// /v3/search/tasks?status=${status} old url
export function getTaskList(
  payload?: TaskSearchPayload,
  pageNumber?: number,
  limit?: number
): Promise<any> {
  return restApiClient.post(
    `/v3/process/task/search?page=${pageNumber}&limit=${limit}`,
    payload
  );
}

/**
 * GET Get lead details for a particular lead
 * @param leadId
 * @param query
 * @returns {Promise}
 */
export function getLeadDetails(leadId: string, query?: any): Promise<any> {
  const leadSearchQuery = `id=${leadId}${
    !isEmpty(query) ? `&${stringify(query)}` : ''
  }`;
  return restApiClient.fetch(`/v3/process/lead/leadDetail?${leadSearchQuery}`);
}

/**
 * GET get complete Process Config
 * @returns {Promise}
 */
export function getProcessConfig(): Promise<any> {
  return restApiClient.fetch(`/v3/process/config`);
}

/**
 * POST Process a certain task
 * @param taskInfo
 * @returns {Promise}
 */
export function processTask(taskInfo: TaskExecutePayload): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Updating task status' });
  return restApiClient.post('/v3/process/task/execute', taskInfo);
}

/**
 * POST Get a list of tasks based on search criteria
 * @param searchQuery
 * @returns {Promise}
 */
// Query Params: pageLimit, pageOffset
export function searchTasks(searchQuery: any): Promise<any> {
  if (!isEmpty(searchQuery)) {
    return restApiClient.post('/v3/process/task/search', searchQuery);
  }
  return getTaskList();
}

/**
 * GET Get a certain customer based on search criteria: supportal search
 * @param searchItem
 * @returns {Promise}
 */
export function searchApplication(searchItem: any): Promise<any> {
  return restApiClient.fetch(
    `/rest-api/v3/portal/search?${stringify(searchItem)}`
  );
}

/**
 * GET get city, state for given pincode
 * @param pincode {payload}
 * @returns {Promise}
 */
export function getCityState(payload: any): Promise<any> {
  return restApiClient.fetch(`/rest-api/lookup/pincode/${payload}`);
}

/**
 * GET Returns application data based on application id
 * @param applicationId
 * @param sections
 * @param processKey
 * @returns {Promise}
 */
export function getApplicationData(
  applicationId: string,
  sections: string[]
): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Getting application data' });
  const queryParams = {
    sections: sections.toString(),
  };
  return restApiClient.fetch(
    `/rest-api/v3/portal/applications/${applicationId}/customer-data?${decodeURIComponent(
      stringify(queryParams)
    )}`
  );
}

/**
 * GET Returns overall audits for a certain application
 * @param leadId
 * @returns {Promise}
 */
export function getOverallAudits(leadId: string): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Getting overall audits' });
  return restApiClient.fetch(`/v3/process/audit?leadId=${leadId}`);
}

/**
 * POST Updates the relevant sections for a certain application
 * @param applicationId
 * @param sections
 * @param payload
 * @returns {Promise}
 */
export function updateApplicationData(payload: any): Promise<any> {
  return restApiClient.post(
    `/rest-api/v2/customer/data-collection/customer-details`,
    payload
  );
}

/**
 * POST Schedule an appointment for mandate document pickup
 * @param customerId
 * @param payload
 * @returns {Promise}
 */
export function scheduleAppointment(
  customerId: string,
  payload: any
): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Scheduling appointment' });
  return restApiClient.post(
    `/rest-api/v2/appointment/schedule?customerId=${customerId}`,
    payload
  );
}

/**
 * POST For initiating a call with customer
 * @param payload
 * @returns {Promise}
 */
export function callCustomer(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Calling customer' });
  return restApiClient.post(`/rest-api/v1/crm/call/connect`, payload);
}

/**
 * GET Get detailed document info for a document type
 * payload.customerId {string}
 * payload.docIds {string[]}
 * @param {any} payload
 * @returns {Promise}
 */
export function getDocumentInfo(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Getting document information',
  });
  return restApiClient.post(
    '/rest-api/v2/portal/customer/analysis/document',
    payload
  );
}

/**
 * POST Post status of document ids
 * payload.customerId {string}
 * payload.docIds {string[]}
 * payload.reason {string}
 * payload.status {string}
 * @param {Object} payload
 * @returns {Promise}
 */
export function setDocumentStatus(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Updating document status' });
  return restApiClient.post(
    '/rest-api/v2/portal/customer/analysis/doc',
    payload
  );
}

/**
 * GET Prefill NACH
 * @param customerId
 * @returns {Promise}
 */
export function getPrefilledNach(customerId: string): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Generating pre-filled NACH' });
  return restApiClient.fetch(
    `/rest-api/v1/customer/nach/prefill?customerId=${customerId}`
  );
}

/**
 * POST Generate magic link for the customer
 * @param {Object} payload
 * @returns {Promise}
 */
export function generateMagicLink(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Generating Magic Link' });
  return restApiClient.post(
    '/rest-api/v1/portal/application/magic_link/generate',
    payload
  );
}

/**
 * POST Saves document identifier details
 * @param {Object} payload
 * @returns {Promise}
 */
export function saveDocumentIdentifierDetails(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Saving document identifier details',
  });
  return restApiClient.post(
    '/rest-api/v1/portal/document/update/identifier',
    payload
  );
}

/**
 * POST Mark beneficiary details as verified
 * @param payload
 * @returns {Promise}
 */
export function markBeneficiaryVerified(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Marking beneficiary details as verified',
  });
  return restApiClient.post(
    '/rest-api/v1/customer/beneficiary/verify',
    payload
  );
}

/**
 * POST Reregister Nach form / submit nach to partner
 * @param {String} externalId
 * @returns {Promise}
 */
export function reRegisterNach(externalId: string): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Re-registering NACH' });
  return restApiClient.post(`/rest-api/v1/renach/${externalId}`);
}

/**
 * POST Initiate penny drop
 * @param {String} applicationId
 * @returns {Promise}
 */
export function initiatePennyDrop(applicationId: string): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Initiating Penny Drop' });
  return restApiClient.post('/rest-api/v1/customer/pennydrop/retry', {
    applicationId,
  });
}

/**
 * POST Apply Available Offers
 * @param payload
 * @returns {Promise}
 */
export function applyAvailableOffers(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Applying Available Offers' });
  return restApiClient.post(
    '/rest-api/v1/customer/creditline/options',
    payload
  );
}

/**
 * POST Cancel emandate with reason
 * @param {Object} payload
 * @returns {Promise}
 */
export function cancelEmandate(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Canceling e-mandate' });
  return restApiClient.post('/rest-api/v1/emandate/cancel', payload);
}

/**
 * POST Cancel emandate with reason
 * @param {Object} payload
 * @returns {Promise}
 */
export function markPickupDone(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Marking Pickup Done' });
  return restApiClient.post('/v3/process/pickup-completed', payload);
}

/**
 * POST Update metadata for a specific document id
 * @param {Object} payload
 * @returns {Promise}
 */
export function updateDocumentMetadata(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Updating document metadata' });
  return restApiClient.post(
    '/rest-api/v1/portal/document/update/metadata',
    payload
  );
}

/**
 * GET Customer Details
 * @param {String} applicationId
 * @param {String} docId
 * @returns {Promise}
 */
export function deleteCustomerDocument(
  applicationId: string,
  docId: string
): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Deleting document' });
  return restApiClient.fetch(
    `/rest-api/v1/portal/document/delete?applicationId=${applicationId}&docId=${docId}`
  );
}

/**
 * POST Delete multiple documents
 * @param {Object} docsToDelete
 * @param {String} docsToDelete.applicationId
 * @param {Array} docsToDelete.docIds
 * @returns {Promise}
 */
export function deleteDocuments({
  applicationId,
  docIds,
}: {
  applicationId: string;
  docIds: string[];
}): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Deleting selected document(s)',
  });
  return restApiClient.post('/rest-api/v1/portal/document/delete', {
    applicationId,
    docIds,
  });
}

/**
 * POST Copy multiple documents
 * @param {Object} docsToCopy
 * @param {String} docsToCopy.applicationId
 * @param {Array} docsToCopy.docIds
 * @returns {Promise}
 */
export function copyDocuments({
  applicationId,
  docIds,
}: {
  applicationId: string;
  docIds: string[];
}): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Copying selected document(s)',
  });
  return restApiClient.post('/rest-api/v1/portal/document/copy', {
    applicationId,
    docIds,
  });
}

/**
 * POST Update document purpose and type for a certain doc id
 * @param {Object} context
 * @param {String} context.documentType
 * @param {String} context.purpose
 * @param {Array} docsToCopy.docIds
 * @returns {Promise}
 */
export function updateDocumentInfo({
  documentType,
  docIds,
  purpose,
}: {
  documentType: string;
  docIds: string[];
  purpose: string;
}): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Updating document info' });
  return restApiClient.post('/rest-api/v1/portal/document/update/type', {
    documentType,
    ids: docIds,
    purpose,
  });
}

/**
 * POST Masks multiple documents
 * @param {Object} payload
 * @param {Array} payload.documentIds
 * @returns {Promise}
 */
export function maskAadhaarDocuments({
  documentIds,
}: {
  documentIds: string[];
}): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Masking document' });
  return restApiClient.post('/rest-api/v1/portal/document/aadhaar/mask', {
    documentIds,
  });
}

/**
 * POST Updates customer details
 * @param {Object} customerInfo
 * @returns {Promise}
 */
export function updateCustomerDetails(customerInfo: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Updating customer details' });
  return restApiClient.post(
    '/rest-api/v2/customer/buildprofile/portal',
    customerInfo
  );
}

/**
 * POST Upload document
 * @param {String} customerId
 * @param {Object} data
 * @returns {Promise}
 */

export async function uploadDocument(
  customerId: string,
  data: any
): Promise<any> {
  const headers: any = getHeaders({
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
  });
  const response: any = await fetch(
    `${process.env.VUE_APP_BASE_URL}/rest-api/v1/customer/${customerId}/upload/files?source=PORTAL`,
    {
      method: 'POST',
      body: data,
      headers,
    }
  );
  return fetchHandler(response);
}

/**
 * POST Get next assigned task for agent based on criteria
 * @param payload
 * @returns {Promise}
 */
export function getNextAssignedTask(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Getting next task' });
  return restApiClient.post('/v1/users/current/task-assignment/auto', payload);
}

/**
 * POST Assign a certain task to an agent
 * @param taskId
 * @returns {Promise}
 */
export function assignTask(taskId: string): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Assigning task' });
  return restApiClient.post('/v1/users/current/task-assignment', { taskId });
}

/**
 * POST Unassign a certain task from an agent
 * @param taskId
 * @returns {Promise}
 */
export function unassignTask(taskId: string): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Unassigning task' });
  return restApiClient.post('/v1/users/current/unclaim-task', { taskId });
}

/**
 * POST Re-Evaluate Salary for a customer
 * @param {Object} payload
 * @returns {Promise}
 */
export function reEvaluateSalary(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Re-calculating salary' });
  return restApiClient.post(
    '/rest-api/v1/portal/application/revaluate',
    payload
  );
}

/**
 * POST Save required data
 * @param payload
 * @returns {Promise}
 */
export function saveRequiredData(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Saving Data' });
  return restApiClient.post(
    '/rest-api/v1/portalnext/customer-data-required',
    payload
  );
}

/**
 * POST Mark Customer hold with reason
 * @param {Object} payload
 * @returns {Promise}
 */
export function markHold(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Marking task on hold' });
  return restApiClient.post('/v3/process/task/mark-hold', payload);
}

/**
 * POST Mark Customer unhold
 * @param {Object} payload
 * @returns {Promise}
 */
export function markUnHold(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Unblocking task from hold status',
  });
  return restApiClient.post('/v3/process/task/mark-unhold', payload);
}

/**
 * GET Fetch a specific customer data section
 * @param section
 * @param applicationId
 * @returns {Promise}
 */
export function fetchCustomerSection(
  section: string,
  applicationId: string
): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Fetching customer data.' });
  return restApiClient.fetch(
    `/rest-api/v3/portal/applications/${applicationId}/customer-section-data?section=${section}`
  );
}

/**
 * POST update document status
 * @param {Object} payload
 * @returns {Promise}
 */
export function updateDocumentStatus(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Update Document status' });
  return restApiClient.post(
    `/rest-api/v1/portalnext/customer-data-update`,
    payload
  );
}

/**
 * POST update document status
 * @param customerId
 * @returns {Promise}
 */
export function markCpvProofRequired(customerId: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Mark CPV Proof Required' });
  return restApiClient.post(
    `/rest-api/v2/portal/application/mark-cpv-doc-required?customerId=${customerId}`
  );
}

/**
 * POST update document status
 * @param customerId
 * @returns {Promise}
 */
export function markItrOrForm16Required(customerId: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Mark ITR Form 16 Required' });
  return restApiClient.post(
    `/rest-api/v1/portal/application/kyc/mark_itr_form16_required?customerId=${customerId}`
  );
}

/**
 * Update rehit details
 * @returns {Promise}
 */
export function rehit(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Rehit Details' });
  return restApiClient.post(`/rest-api/v1/portal/rehit`, payload);
}

/**
 * Update rehit details
 * @returns {Promise}
 */
export function updateMetaData(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Update meta Data' });
  return restApiClient.post(
    '/rest-api/v1/portal/document/update/metadata',
    payload
  );
}

/**
 * Update rehit details
 * @returns {Promise}
 */
export function validatePan(customerId: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Validate Pan' });
  return restApiClient.post('/rest-api/v1/customer/pan/verify', { customerId });
}

/**
 * Update Company Details details
 * @returns {Promise}
 */
export function companyList(bankPartner: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Update Company List' });
  return restApiClient.fetch(`/rest-api/v1/companylist/${bankPartner}`);
}

/**
 * Get ProcessInfo
 * @returns {Promise}
 */
export function getProcesInfo(processId: string): Promise<any> {
  return restApiClient.fetch(
    `/v3/process/instance-data?processInstanceId=${processId}`
  );
}

/**
 *
 * @returns {Promise}
 */
export function requestPartnerApproval(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Loading...' });
  return restApiClient.post(
    `/rest-api/v1/support/ops/request_partner_approval`,
    payload
  );
}

/**
 *
 * @returns {Promise}
 */
export function markCustomerAllActionsComplete(leadId: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Loading...' });
  return restApiClient.post(
    `/v3/process/mark-customer-all-actions-complete?leadId=${leadId}`
  );
}

/**
 * Update Company Details details
 * @returns {Promise}
 */
export function getRequirementDetailsConfig(applicationId: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Loading' });
  return restApiClient.fetch(
    `/rest-api/v3/portal/config/${applicationId}/requirement-details`
  );
}

/**
 * POST Update penny drop name
 * @returns {Promise}
 */
export function updatePennydropName(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Updating details ...' });
  return restApiClient.post(`/rest-api/v1/customer/pennydrop/name`, payload);
}

/**
 * POST Send RBL VKC link to customer
 * @param payload
 * @returns {Promise}
 */
export function sendVkycLinkForRbl(payload: any): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Sending VKYC link to customer',
  });
  return restApiClient.post('/rest-api/v1/rbl/vkyc/send-link', payload);
}

/**
 * POST Update RBL Fulfillment Status
 * @param {String} applicationId
 * @returns {Promise}
 */
export function updateRblFulfillmentStatus(
  applicationId: string
): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Updating RBL Fulfillment Status',
  });
  return restApiClient.post(
    `/rest-api/v1/rbl/fulfillment/update-status?applicationId=${applicationId}`
  );
}

/**
 * GET Get pending step offers
 * @param {String} customerId
 * @returns {Promise}
 */
export function getPendingStepIncentivisationOffers(
  customerId: string
): Promise<any> {
  store.dispatch('triggerApiStart', {
    message: 'Getting incentivisation offers',
  });
  return restApiClient.fetch(
    `/rest-api/v1/customer/offer/eligibleOffers?customerId=${customerId}`
  );
}

/**
 * GET For initiating a call with customer
 * @param leadId
 * @returns {Promise}
 */
export function getCallAudits(leadId: string): Promise<any> {
  store.dispatch('triggerApiStart', { message: 'Getting call audits' });
  return restApiClient.fetch(`/v1/auto-dialer/call/audit?leadId=${leadId}`);
}
