<template>
  <svg
    width="20px"
    height="18px"
    class="print-icon"
    viewBox="0 0 20 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/Document/ViewMode-Copy-4"
        transform="translate(-744.000000, -143.000000)"
      >
        <g class="print-24px" transform="translate(742.000000, 140.000000)">
          <polygon class="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M19,8 L18,8 L18,3 L6,3 L6,8 L5,8 C3.34,8 2,9.34 2,11 L2,17 L6,17 L6,21 L18,21 L18,17 L22,17 L22,11 C22,9.34 20.66,8 19,8 Z M8,5 L16,5 L16,8 L8,8 L8,5 Z M16,17 L16,19 L8,19 L8,15 L16,15 L16,17 Z M18,15 L18,13 L6,13 L6,15 L4,15 L4,11 C4,10.45 4.45,10 5,10 L19,10 C19.55,10 20,10.45 20,11 L20,15 L18,15 Z"
            class="color"
            fill="#363636"
            fill-rule="nonzero"
          ></path>
          <circle
            class="color"
            fill="#363636"
            fill-rule="nonzero"
            cx="18"
            cy="11.5"
            r="1"
          ></circle>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.print-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
