<template>
  <svg
    width="15px"
    class="call-icon"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/CustomerDetails"
        transform="translate(-123.000000, -361.000000)"
      >
        <g class="Group-3" transform="translate(75.000000, 0.000000)">
          <g class="call-24px-2" transform="translate(45.000000, 358.000000)">
            <polygon class="Path" points="0 0 21 0 21 21 0 21"></polygon>
            <path
              d="M5.95,4.66666667 C6,5.40833333 6.125,6.13333333 6.325,6.825 L5.325,7.825 C4.98333333,6.825 4.76666667,5.76666667 4.69166667,4.66666667 L5.95,4.66666667 M14.1666667,14.6833333 C14.875,14.8833333 15.6,15.0083333 16.3333333,15.0583333 L16.3333333,16.3 C15.2333333,16.225 14.175,16.0083333 13.1666667,15.675 L14.1666667,14.6833333 M6.75,3 L3.83333333,3 C3.375,3 3,3.375 3,3.83333333 C3,11.6583333 9.34166667,18 17.1666667,18 C17.625,18 18,17.625 18,17.1666667 L18,14.2583333 C18,13.8 17.625,13.425 17.1666667,13.425 C16.1333333,13.425 15.125,13.2583333 14.1916667,12.95 C14.1083333,12.9166667 14.0166667,12.9083333 13.9333333,12.9083333 C13.7166667,12.9083333 13.5083333,12.9916667 13.3416667,13.15 L11.5083333,14.9833333 C9.15,13.775 7.21666667,11.85 6.01666667,9.49166667 L7.85,7.65833333 C8.08333333,7.425 8.15,7.1 8.05833333,6.80833333 C7.75,5.875 7.58333333,4.875 7.58333333,3.83333333 C7.58333333,3.375 7.20833333,3 6.75,3 Z"
              class="Shape color"
              fill="#363636"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.call-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
