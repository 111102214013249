<template>
  <svg width="16px" height="18px" viewBox="0 0 16 18" class="dashboard-icon">
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/CustomerDetails"
        transform="translate(-32.000000, -152.000000)"
      >
        <g class="Group">
          <g
            class="ic_format_list_bulleted-copy"
            transform="translate(25.000000, 146.000000)"
          >
            <g class="Icon-24px">
              <path
                d="M22.5,23.75 L22.5,10 L20,10 L20,23.75 L22.5,23.75 L22.5,23.75 Z M16.25,23.75 L16.25,6.25 L13.75,6.25 L13.75,23.75 L16.25,23.75 L16.25,23.75 Z M7.5,23.75 L10,23.75 L10,15 L7.5,15 L7.5,23.75 L7.5,23.75 Z"
                class="color"
                fill="#000000"
              ></path>
              <polygon class="Shape" points="0 0 30 0 30 30 0 30"></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.dashboard-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
