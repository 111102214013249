<template>
  <svg
    width="16px"
    height="17px"
    class="download-icon"
    viewBox="0 0 16 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/Document/ViewMode-Copy-4"
        transform="translate(-682.000000, -144.000000)"
      >
        <g
          class="ic_rotate_right-copy"
          transform="translate(678.000000, 140.000000)"
        >
          <g class="Icon-24px">
            <polygon class="Shape" points="0 0 24 0 24 24 0 24"></polygon>
            <g
              class="color"
              transform="translate(4.000000, 4.000000)"
              fill="#363636"
            >
              <polygon
                class="Path"
                fill-rule="nonzero"
                points="8.99817231 0 7.00266371 0 7.00266371 8.99503962 4 8.99503962 8 12.9950396 12 8.99503962 8.99817231 8.99503962"
              ></polygon>
              <rect
                class="Rectangle"
                transform="translate(8.000000, 16.000000) scale(1, -1) translate(-8.000000, -16.000000) "
                x="0"
                y="15"
                width="16"
                height="2"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.download-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
