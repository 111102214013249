<template>
  <svg
    width="18px"
    height="18px"
    class="fullscreen-icon"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/Document/ViewMode-Copy-4"
        transform="translate(-809.000000, -143.000000)"
      >
        <g class="ic_open_in_new" transform="translate(806.000000, 140.000000)">
          <g class="Icon-24px">
            <polygon class="Shape" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M19,19 L5,19 L5,5 L12,5 L12,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12 L19,12 L19,19 L19,19 Z M14,3 L14,5 L17.59,5 L7.76,14.83 L9.17,16.24 L19,6.41 L19,10 L21,10 L21,3 L14,3 L14,3 Z"
              class="color"
              fill="#363636"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.fullscreen-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
