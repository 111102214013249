<template>
  <svg
    width="14px"
    height="8px"
    viewBox="0 0 14 8"
    class="arrow-icon"
    :class="iconDirection"
  >
    <g
      class="TasksMain"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        class="color"
        transform="translate(-715.000000, -71.000000)"
        stroke="#979797"
        stroke-width="2"
      >
        <polyline
          class="Path-3-Copy-4"
          points="716 72 722 78 728 72"
        ></polyline>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup>
import { onMounted, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    iconColor?: string;
    iconDirection?: string;
  }>(),
  {
    iconDirection: 'left', // Default value is 'left'
    iconColor: '', // Default value is an empty string
  }
);

onMounted(setColor);

function setColor() {
  if (props.iconColor !== '') {
    const ele = document.querySelector('.color');
    if (ele) {
      (ele as any).style.stroke = props.iconColor;
    }
  }
}

watch(() => props.iconColor, setColor);
</script>

<style lang="scss">
.arrow-icon {
  --icon-color: #363636;
  .color {
    stroke: var(--icon-color);
  }
  &.left {
    transform: rotate(90deg);
  }
  &.right {
    transform: rotate(-90deg);
  }
  &.up {
    transform: rotate(180deg);
  }
  &.down {
    transform: rotate(0deg);
  }
}
</style>
