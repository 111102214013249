<template>
  <div class="ProfileMenu">
    <name-avatar :name="userName"></name-avatar>
    <p class="popContent">
      {{ userName }}<br />
      {{ organisation }}
    </p>
    <a-row type="flex" justify="center">
      <button class="actionButton outline" @click="signOut">Logout</button>
    </a-row>
  </div>
</template>
<script lang="ts" setup>
import { Row as ARow } from 'ant-design-vue';
import NameAvatar from '@/components/misc/NameAvatar.vue';

defineProps<{
  userName: string;
  organisation: string;
}>();

const emit = defineEmits(['logout']);

function signOut() {
  emit('logout');
}
// @Component({
//   components: {
//     'a-row': Row,
//     NameAvatar,
//   },
// })
// export default class ProfileMenu extends Vue {
//   @Prop() private userName!: string;
//   @Prop() private organisation!: string;

//   private signOut() {
//     this.$emit('logout');
//   }
// }
</script>

<style lang="scss">
.ProfileMenu {
  padding: 16px 24px;
  min-width: 330px;

  .popContent {
    text-align: center;
    font-weight: var(--font-medium);
    margin-top: 12px;
  }

  .actionButton {
    height: 40px;
    width: 100%;
    font-family: Roboto;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff !important;
    background-color: #2b65f0;
    border-radius: 6px;
    padding: 8px 16px 8px 16px;

    &:hover {
      cursor: pointer;
    }

    &.sm {
      font-size: 14px !important;
    }

    &.clear {
      background: transparent;
      border: none;
      color: #2b65f0 !important;
    }

    &.outline {
      background: transparent;
      border: solid 2px #2b65f0;
      color: #2b65f0 !important;

      &.disabled {
        color: #e7e7e7 !important;
        border: solid 2px #e7e7e7;
      }

      &.danger {
        color: #d22626 !important;
        border: 2px solid #d22626;
      }
    }
  }
}
</style>
