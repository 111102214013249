import { createStore } from 'vuex';
import getters from './getters';
import * as actions from './actions';
import mutations from './mutations';

const store = createStore({
  state: {
    sideBar: {
      opened: false,
    },
    loaderData: {
      fullScreen: false,
      message: 'Loading...',
      spinning: false,
    },
    isMobile: false,
    userProfile: {},
    customerDetails: {},
    tasks: [],
    sections: [],
    leadDetail: {},
    selectedPartner: null,
    searchSchema: {},
    savedSearchQueries: {},
    processingRequest: false,
    currentConfig: null,
    activeFilters: {},
    searchParameters: {},
    searchPagination: {
      currentPage: 1,
      pageSize: 14,
    },
    globalSearchParameters: {},
    processConfig: null,
    enableAutoAssignment: false,
    taskUpdateConditions: {
      dataCollection: false,
      appointment: false,
    },
    taskNames: [],
    updateMetaDataConfig: [],
    readonlyAccess: false,
    leadId: null,
    companyList: null,
    requirementDetailsConfig: {},
    callAudits: [],
    callAuditsApiCalled: false,
    applicationToLeadIdMap: {},
  },
  actions,
  mutations,
  getters,
  modules: {},
});
export default store;
