<template>
  <div class="SideMenu">
    <div class="menuList">
      <div class="menuItem">
        <dashboard-icon class="icon"></dashboard-icon>
        <p class="tooltip">Dashboard</p>
      </div>
      <div
        class="menuItem"
        @click="gotoRoute('tasks')"
        :class="{
          active: $route.name === 'tasks',
        }"
      >
        <tasks-icon class="icon" />
        <p class="tooltip">Tasks</p>
      </div>
      <div
        class="menuItem"
        @click="gotoRoute('search')"
        :class="{
          active: $route.name === 'search' || $route.name === 'application',
        }"
      >
        <search-icon class="icon" />
        <p class="tooltip">Search</p>
      </div>
      <div
        class="menuItem hiddenMenuOpener"
        v-if="router.currentRoute.value.name === 'application'"
        @click="toggleHiddenMenu($event)"
      >
        <arrow-icon class="icon" icon-direction="up"></arrow-icon>
        <p class="tooltip">Others</p>
      </div>
    </div>
    <div>
      <!-- <div class="menuItem2">
          <switch-button :current-mode="darkMode" @change="toggleDarkMode"></switch-button>
          <p class="tooltip">Dark mode</p>
        </div> -->

      <a-popover placement="rightBottom">
        <template #content>
          <profile-menu
            :userName="(userProfile && userProfile.userName) || ''"
            :organisation="(userProfile && userProfile.organisation) || ''"
            @logout="signOut"
          ></profile-menu>
        </template>
        <div class="userAccount">
          <!-- <a-icon type="menu" /> -->
          <MenuOutlined></MenuOutlined>
        </div>
      </a-popover>
    </div>
  </div>
</template>

<script lang="ts" setup>
import eventBus from '@/lib/event_bus';
import { Popover as APopover } from 'ant-design-vue';
import {
  ArrowIcon,
  DashboardIcon,
  SearchIcon,
  TasksIcon,
} from '@/components/icons';

import ProfileMenu from '@/components/misc/ProfileMenu.vue';
import { StoreAction, StoreMutation } from '@/lib/utils';
import router from '@/router';
import { MenuOutlined } from '@ant-design/icons-vue';
import { computed } from 'vue';
import store from '@/store';
defineProps<{
  darkMode: boolean;
}>();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetUserProfile = (response: any) =>
  StoreMutation('UPDATE_USER_PROFILE', response);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetBankPartner = (bankPartner: string) =>
  StoreMutation('SET_CURRENT_PARTNER', bankPartner);

const doLogout = (data: { callApi: boolean }) =>
  StoreAction('doLogout', data.callApi);

const userProfile = computed<any>(() => store.state.userProfile);

function toggleHiddenMenu(evt: Event) {
  evt.stopImmediatePropagation();
  const hiddenMenu = document.querySelector('.hiddenMenu');
  if (hiddenMenu) {
    eventBus.emit('show-hidden-menu', !hiddenMenu.classList.contains('open'));
  }
}

function signOut() {
  doLogout({ callApi: true });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function toggleDarkMode() {
  eventBus.emit('toggle-theme');
}

function gotoRoute(routeName: string) {
  if (router.currentRoute.value.name !== routeName) {
    router.push({ name: routeName });
  }
}
</script>

<style lang="scss">
.SideMenu {
  width: 60px;
  height: 100%;
  background: var(--bg-color);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 20px 0 rgba(var(--dark-color-rgb), 0.1);
  z-index: 100;
  @media screen and (max-width: $sm) {
    width: 100%;
    height: 60px;
    flex-direction: row;
  }
  .menuList {
    margin-top: 10vh;
    @media screen and (max-width: $sm) {
      margin: 0;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-grow: 1;
    }
  }
  .menuItem2 {
    display: flex;
    height: 60px;
    width: 60px;
    align-items: center;
    justify-content: center;
  }
  .menuItem,
  .userAccount {
    display: flex;
    height: 60px;
    width: 60px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: var(--menu-hover-color);
      .icon {
        --icon-color: var(--primary-color);
      }
    }
    &.active {
      background-color: var(--menu-hover-color);
      border-left: 3px solid var(--primary-color);
      .icon {
        --icon-color: var(--primary-color);
      }
    }
    .icon {
      width: 20px;
      height: 20px;
      --icon-color: var(--dark-color);
    }
  }
  .menuItem,
  .menuItem2 {
    position: relative;
    @media screen and (max-width: $sm) {
      flex-grow: 1;
    }
    &:hover {
      .tooltip {
        @media screen and (min-width: $sm) {
          display: block;
        }
      }
    }
    .tooltip {
      position: absolute;
      left: 68px;
      z-index: 100;
      margin: 0;
      background: rgba(var(--dark-color-rgb), 0.7);
      color: var(--light-color);
      padding: 2px 12px;
      border-radius: 6px;
      width: max-content;
      display: none;
      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 6px solid transparent;
        border-right: 4px solid rgba(var(--dark-color-rgb), 0.7);
        border-top: 6px solid transparent;
        left: -4px;
        top: 50%;
        z-index: -1;
        transform: translateY(-50%);
      }
    }
  }
  .hiddenMenuOpener {
    @media screen and (min-width: $sm) {
      display: none;
    }
    &:hover {
      background-color: var(--menu-hover-color);
      .icon {
        --icon-color: var(--primary-color);
        .color {
          stroke: var(--primary-color);
        }
      }
    }
  }
}
</style>
