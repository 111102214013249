import { isEmpty, get } from '@/lib/helpers';
import { getLocalKey } from '@/store/localStore';
import { USER_PROFILE, CURRENT_LEAD_ID, COMPANY_LIST } from './localKeys';
export default {
  sideBar: (state: any) => state.sideBar,
  loaderData: (state: any) => state.loaderData,
  userProfile: (state: any) => {
    let userProfile = state.userProfile;
    if (isEmpty(userProfile)) {
      userProfile = getLocalKey(USER_PROFILE);
    }
    return userProfile;
  },
  customerDetails: (state: any) => state.customerDetails,
  roles: (state: any, getters: any) => {
    const userProfile = getters.userProfile;
    if (!isEmpty(userProfile)) {
      return userProfile.roles;
    }
    return [];
  },
  applicationId: (state: any, getters: any) => {
    const customerDetails = getters.customerDetails;
    if (!isEmpty(customerDetails)) {
      return get(customerDetails, 'applicationId', '');
    }
    return '';
  },
  customerId: (state: any, getters: any) => {
    const customerDetails = getters.customerDetails;
    if (!isEmpty(customerDetails)) {
      return get(customerDetails, 'customerId', '');
    }
    return '';
  },
  uploadedDocuments: (state: any, getters: any) => {
    const customerDetails = getters.customerDetails;
    if (!isEmpty(customerDetails)) {
      return get(customerDetails, 'groupedDocumentSection', []);
    }
    return [];
  },
  tasks: (state: any) => state.tasks,
  sections: (state: any) => state.sections,
  leadDetail: (state: any) => state.leadDetail,
  searchQueries: (state: any) => state.savedSearchQueries,
  currentPartner: (state: any, getters: any) => {
    const leadDetail = getters.leadDetail;
    if (!isEmpty(leadDetail)) {
      // TODO: Change logic to get actual partner
      return get(leadDetail, 'partnerCode', 'DEFAULT');
    }
    return 'DEFAULT'; // TODO: Change to empty string later on
  },
  currentLineType: (state: any, getters: any) => {
    const leadDetail = getters.leadDetail;
    if (!isEmpty(leadDetail)) {
      // TODO: Change logic to get actual line type
      return get(leadDetail, 'productType', 'DEFAULT');
    }
    return 'DEFAULT';
  },
  currentPartnerOptions: (state: any, getters: any) => {
    return {
      currentLineType: getters.currentLineType,
      currentPartner: getters.currentPartner,
    };
  },
  activeFilters: (state: any) => state.activeFilters,
  searchParameters: (state: any) => state.searchParameters,
  searchPagination: (state: any) => state.searchPagination,
  globalSearchParams: (state: any) => state.globalSearchParameters,
  autoAssignmentEnabled: (state: any) => state.enableAutoAssignment,
  taskUpdateConditions: (state: any) => state.taskUpdateConditions,
  currentDocItem: (state: any) => state.currentDocItem,
  leadId: (state: any) => {
    let leadId = state.leadId;
    if (isEmpty(leadId)) {
      leadId = getLocalKey(CURRENT_LEAD_ID);
    }
    return leadId;
  },
  companyList: (state: any) => {
    let companyList = state.companyList;
    if (isEmpty(companyList)) {
      companyList = getLocalKey(COMPANY_LIST);
    }
    return companyList;
  },
  requirementDetailsConfig: (state: any) => state.requirementDetailsConfig,
};
