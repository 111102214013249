<template>
  <svg
    width="14px"
    class="delete-icon"
    height="18px"
    viewBox="0 0 14 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/Document/ViewMode-Copy-4"
        transform="translate(-493.000000, -143.000000)"
      >
        <g class="delete-24px" transform="translate(488.000000, 140.000000)">
          <polygon class="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M16,9 L16,19 L8,19 L8,9 L16,9 M14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L15.5,4 L14.5,3 Z M18,7 L6,7 L6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 Z"
            class="color"
            fill="#363636"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.delete-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
