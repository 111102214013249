import { makeFetchClient } from 'so-fetch-js';
import { get } from '@/lib/helpers';
import store from '@/store/index';
import router from '@/router/index';
import { getLocalKey } from '@/store/localStore';
import { USER_PROFILE } from '@/store/localKeys';

const addAuthHeader = (config: any) => {
  let accessToken = get(store, 'state.userProfile.access_token', '');
  let tokenType = get(store, 'state.userProfile.token_type', '');
  if (!accessToken) {
    const userProfile = getLocalKey(USER_PROFILE);
    accessToken = userProfile && userProfile.access_token;
    tokenType = userProfile && userProfile.token_type;
  }
  if (accessToken && tokenType) {
    config.headers.Authorization = `${
      tokenType === 'bearer' ? 'Bearer' : tokenType
    } ${accessToken}`;
  }
  return config;
};

const addJsonHeaders = (config: any) => {
  config.headers = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'Content-Type': 'application/json',
  };
  return config;
};

export const handleApiResponse = (response: any) => {
  const statusCode: number = get(response, 'status', 200);
  if ([401].includes(statusCode)) {
    if (router.currentRoute.value.name !== 'login') {
      store.dispatch('doLogout', { callApi: false });
    } else {
      store.dispatch('doLogout', { callApi: true });
    }
    return Promise.reject(response);
  }
  return response;
};

export const handleApiFinish = (response: any) => {
  store.dispatch('triggerApiComplete');
  return response;
};

const restApiClient = makeFetchClient({
  rootUrl: () => process.env.VUE_APP_BASE_URL,
  requestInterceptors: [addJsonHeaders, addAuthHeader],
  responseInterceptors: [handleApiResponse, handleApiFinish],
});

export default restApiClient;
