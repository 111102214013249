<template>
  <div id="app">
    <loader v-if="loaderData.spinning" :loader-data="loaderData"></loader>
    <AppMain />
  </div>
</template>
<script lang="ts" setup>
import AppMain from '@/components/layout/AppMain.vue';
import Loader from '@/components/loader/LoaderComponent.vue';
import './App.scss';
import { onBeforeMount } from 'vue';
import { StoreGetter, StoreMutation } from './lib/utils';

const updateScreenView = (ismobile: boolean) => {
  StoreMutation('UPDATE_SCREEN_VIEW', ismobile);
};
const initializeSearchQuery = () =>
  StoreMutation('INITIALIZE_SEARCH_QUERIES', null);

const loaderData = StoreGetter('loaderData');

onBeforeMount(() => {
  isMobile();
  window.onresize = () => {
    isMobile();
  };
  initializeSearchQuery();
});

function isMobile() {
  const { isMobile } = StoreGetter('').value;
  const body = document.querySelector('body');
  const bodyWidth = body ? body.offsetWidth : 0;
  if (isMobile && bodyWidth > 768) {
    updateScreenView(false);
  } else if (!isMobile && bodyWidth <= 768) {
    updateScreenView(true);
  }
}
</script>
<style scoped>
@import './App.scss';
</style>
