<template>
  <div class="loader-icon"></div>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.loader-icon {
  --strip-color: #363636;
  --fill-color: #363636;
  border: 16px solid var(--strip-color);
  border-radius: 50%;
  border-top: 16px solid var(--fill-color);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
