export const get = (obj: any, path: string | null, defaultValue: any = null) =>
  String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce(
      (a: any, c) =>
        a && Object.hasOwnProperty.call(a, c) ? a[c] : defaultValue,
      obj
    );

export const isNil = (obj: any): boolean => obj == null || obj === undefined;

export const isNull = (obj: any): boolean => obj == null;

export const isUndefined = (obj: any): boolean => obj === undefined;

export const isNaN = (obj: any): boolean => Number.isNaN(obj);

export const isEmpty = (obj: any): boolean =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const head = (objArray: any[]): any => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstElem, ...tail] = objArray;
  return firstElem || '';
};

export const isFunction = (object: any) => {
  return !!(object && object.constructor && object.call && object.apply);
};
export const sortBy = (object: any[], key?: any) => {
  if (key) {
    return object.concat().sort(sortByHelper(key));
  } else {
    return object.sort();
  }
};

const sortByHelper = (key: any) => {
  return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const intersection = (array: any[], ...restArrays: any[]) => {
  const arrayOfArrays = [array, ...restArrays];
  return arrayOfArrays.reduce((a, b) => a.filter((c: any) => b.includes(c)));
};

export const deleteInPath = (object: any, path: string) => {
  let currentObject = object;
  const parts: string[] = path.split('.');
  const targetProp = parts.pop();
  for (const part of parts) {
    currentObject = currentObject[part];
    if (!currentObject) {
      return;
    }
  }
  if (targetProp) {
    Reflect.deleteProperty(currentObject, targetProp);
  }
  return currentObject;
};

export const omitBy = (object: any, fn: any) =>
  Object.keys(object)
    .filter((objKey) => !fn(object[objKey], objKey))
    .reduce((acc: any, key) => ((acc[key] = object[key]), acc), {});

export const getUniqueArray = (arr: any[]) => {
  const prims: any = { boolean: {}, number: {}, string: {} };
  const objs: any[] = [];

  return arr.filter((item) => {
    const type = typeof item;
    if (type in prims) {
      return Object.prototype.hasOwnProperty.call(prims[type], item)
        ? false
        : (prims[type][item] = true);
    } else {
      return objs.indexOf(item) >= 0 ? false : objs.push(item);
    }
  });
};

export const getUniqueObjectsArray = (arr: any[], keyName: string) => {
  const objs: any = {};
  return arr.filter((item) => {
    return Object.prototype.hasOwnProperty.call(objs, item[`${keyName}`])
      ? false
      : (objs[item[`${keyName}`]] = true);
  });
};

export const snakeCaseToTitleCase = (str: string) =>
  str.replace(/^_*(.)|_+(.)/g, (str, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );

export const camelCaseToTitleCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
};
