<template>
  <svg
    width="20px"
    height="20px"
    class="close-icon"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      class="TasksMain"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        class="color"
        transform="translate(-1108.000000, -123.000000)"
        stroke="#646464"
        stroke-width="3"
      >
        <g class="Group" transform="translate(1110.000000, 125.000000)">
          <line x1="0" y1="0" x2="16" y2="16" id="Path-5"></line>
          <line x1="16" y1="0" x2="0" y2="16" id="Path-5-Copy"></line>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.close-icon {
  --icon-color: #646464;
  .color {
    stroke: var(--icon-color);
  }
}
</style>
