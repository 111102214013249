<template>
  <div :class="{ hidden: !spinning, fullScreen: fullScreen }" class="loader">
    <div class="wrapper">
      <div class="inner" />
      <div class="text">{{ message }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  loaderData: any;
}>();

const spinning = computed(() => {
  return props.loaderData.spinning;
});
const fullScreen = computed(() => {
  return props.loaderData.fullScreen || false;
});
const message = computed(() => {
  return props.loaderData.message || 'Loading';
});
</script>

<style lang="scss" scoped>
.loader {
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  text-align: center;

  &.fullScreen {
    position: fixed;
  }

  .wrapper {
    width: 100px;
    height: 100px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .inner {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    text-indent: -12345px;
    border-top: 2px solid rgba(255, 255, 255, 0.08);
    border-right: 2px solid rgba(255, 255, 255, 0.08);
    border-bottom: 2px solid rgba(255, 255, 255, 0.08);
    border-left: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    z-index: 100001;

    animation: adjust-huener 600ms infinite linear;
  }

  .text {
    width: 100px;
    height: 20px;
    text-align: center;
    font-size: 16px;
    color: #fff;
  }

  &.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 1s ease 0.5s, z-index 0.1s ease 1.5s;
  }
}

@keyframes adjust-huener {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
