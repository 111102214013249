<template>
  <svg width="24px" height="20px" viewBox="0 0 24 20" class="tasks-icon">
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/CustomerDetails"
        transform="translate(-28.000000, -232.000000)"
      >
        <g class="Group">
          <g
            class="ic_format_list_bulleted"
            transform="translate(25.000000, 227.000000)"
          >
            <g class="Icon-24px">
              <path
                d="M5,13.125 C3.9625,13.125 3.125,13.9625 3.125,15 C3.125,16.0375 3.9625,16.875 5,16.875 C6.0375,16.875 6.875,16.0375 6.875,15 C6.875,13.9625 6.0375,13.125 5,13.125 L5,13.125 Z M5,5.625 C3.9625,5.625 3.125,6.4625 3.125,7.5 C3.125,8.5375 3.9625,9.375 5,9.375 C6.0375,9.375 6.875,8.5375 6.875,7.5 C6.875,6.4625 6.0375,5.625 5,5.625 L5,5.625 Z M5,20.8375 C4.075,20.8375 3.3375,21.5875 3.3375,22.5 C3.3375,23.4125 4.0875,24.1625 5,24.1625 C5.9125,24.1625 6.6625,23.4125 6.6625,22.5 C6.6625,21.5875 5.925,20.8375 5,20.8375 L5,20.8375 Z M8.75,23.75 L26.25,23.75 L26.25,21.25 L8.75,21.25 L8.75,23.75 L8.75,23.75 Z M8.75,16.25 L26.25,16.25 L26.25,13.75 L8.75,13.75 L8.75,16.25 L8.75,16.25 Z M8.75,6.25 L8.75,8.75 L26.25,8.75 L26.25,6.25 L8.75,6.25 L8.75,6.25 Z"
                class="color"
                fill="#2B65F0"
              ></path>
              <polygon class="Shape" points="0 0 30 0 30 30 0 30"></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.tasks-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
