const localStore = window.localStorage;

/**
 * Set a key-value pair in local storage
 * @param {String} key
 * @param {any} data
 */
export function setLocalKey(key: string, data: any): void {
  if (localStore) {
    localStore.setItem(key, JSON.stringify(data));
  }
}

/**
 * Returns the value for the key provided from local storage
 * @param {String} key
 * @returns {any|null}
 */
export function getLocalKey(key: string): any | null {
  try {
    if (localStore) {
      const persistedValue = localStore.getItem(key);
      if (persistedValue) {
        return JSON.parse(persistedValue);
      }
      return null;
    }
  } catch (err) {
    localStore.removeItem(key);
    return null;
  }
  return null;
}

/**
 * Deletes a particular key-value pair
 * @param {String} key
 */
export function deleteLocalKey(key: string): void {
  localStore.removeItem(key);
}
