import { setLocalKey, getLocalKey } from '@/store/localStore';
import {
  USER_PROFILE,
  SAVED_SEARCH_QUERIES,
  SAVED_ACTIVE_QUERIES,
  PROCESS_CONFIG,
  COMPANY_LIST,
  APPLICATION_TO_LEAD_ID_MAP,
} from '@/store/localKeys';
import { get } from '@/lib/helpers';
import { isEmpty } from 'lodash';

export default {
  UPDATE_USER_PROFILE(state: any, userProfile: any) {
    state.userProfile = userProfile;
    setLocalKey(USER_PROFILE, userProfile);
  },
  UPDATE_SCREEN_VIEW(state: any, isMobile: boolean) {
    state.isMobile = isMobile;
  },
  TOGGLE_SIDEBAR(state: any) {
    state.sideBar.opened = !state.sideBar.opened;
  },
  SET_CURRENT_PARTNER(state: any, currentPartner: string) {
    state.selectedPartner = currentPartner;
  },
  UPDATE_CUSTOMER_DETAILS(state: any, customerDetails: any) {
    state.customerDetails = customerDetails;
  },
  UPDATE_LEAD_DETAILS(state: any, leadDetails: any) {
    state.sections = leadDetails.sections;
    state.tasks = leadDetails.tasks;
    state.leadDetail = leadDetails.leadDetail;
  },
  SAVE_SEARCH_QUERY(state: any, searchSchema: any) {
    state.savedSearchQueries[searchSchema.id] = searchSchema;
    setLocalKey(SAVED_SEARCH_QUERIES, state.savedSearchQueries);
  },
  INITIALIZE_SEARCH_QUERIES(state: any) {
    const savedSearchQueries = getLocalKey(SAVED_SEARCH_QUERIES) || {};
    state.savedSearchQueries = savedSearchQueries;
    const activeFilters = getLocalKey(SAVED_ACTIVE_QUERIES) || {};
    state.activeFilters = activeFilters;
  },
  UPDATE_CURRENT_CONFIG(state: any, config: any) {
    state.currentConfig = config;
  },
  SAVE_SEARCH_PARAMETERS(state: any, searchObject: any) {
    state.searchParameters = searchObject;
  },
  SAVE_PAGINATION(state: any, pagination: any) {
    state.searchPagination = Object.assign(
      {},
      state.searchPagination,
      pagination
    );
  },
  SAVE_GLOBAL_SEARCH_PARAMS(state: any, searchObject: any) {
    state.globalSearchParameters = searchObject;
  },
  SAVE_ACTIVE_FILTERS(state: any, activeFilters: any) {
    state.activeFilters = activeFilters;
    setLocalKey(SAVED_ACTIVE_QUERIES, state.activeFilters);
  },
  SAVE_PROCESS_CONFIG(state: any, config: any) {
    state.processConfig = config;
    setLocalKey(PROCESS_CONFIG, config);
  },
  TOGGLE_AUTO_ASSIGNMENT(state: any, enableAutoAssignment: boolean) {
    state.enableAutoAssignment = enableAutoAssignment;
  },
  UPDATE_LOADER_DATA(state: any, loaderData: any) {
    state.loaderData = loaderData;
  },
  UPDATE_TASK_CONDITIONS(state: any, conditions: any) {
    state.taskUpdateConditions = Object.assign(
      {},
      state.taskUpdateConditions,
      conditions
    );
  },
  SAVE_TASK_NAMES(state: any, taskNames: any) {
    state.taskNames = taskNames;
  },
  UPDATE_METADATA_CONFIG(state: any, config: any) {
    state.updateMetaDataConfig = config;
  },
  UPDATE_READONLY_ACCESS(state: any, readonlyAccess: boolean) {
    state.readonlyAccess = readonlyAccess;
  },
  // SAVE_LEAD_ID(state: any, leadId: any) {
  //   state.leadId = leadId;
  //   setLocalKey(CURRENT_LEAD_ID, leadId);
  // },
  UPDATE_COMPANY_LIST(state: any, companyList: any) {
    state.companyList = companyList;
    setLocalKey(COMPANY_LIST, companyList);
  },
  UPDATE_REQUIREMENT_DETAILS(state: any, requirements: any) {
    state.requirementDetailsConfig = requirements;
  },
  SAVE_CALL_AUDITS(state: any, callAudits: any[]) {
    state.callAudits = callAudits;
  },
  UPDATE_APPLICATION_TO_LEAD_ID_MAP(
    state: any,
    { leadId, applicationId }: { leadId: any; applicationId: any }
  ) {
    if (applicationId) {
      const leadIdInMap = get(
        state.applicationToLeadIdMap,
        applicationId,
        null
      );
      if (leadIdInMap) {
        state.leadId = leadIdInMap;
      } else {
        state.applicationToLeadIdMap[applicationId] = leadId;
        state.leadId = leadId;
        setLocalKey(APPLICATION_TO_LEAD_ID_MAP, state.applicationToLeadIdMap);
      }
    }
  },
  UPDATE_CURRENT_LEAD_ID(state: any, applicationId: any) {
    if (applicationId && !isEmpty(applicationId)) {
      state.applicationToLeadIdMap =
        getLocalKey(APPLICATION_TO_LEAD_ID_MAP) || {};
      if (!isEmpty(state.applicationToLeadIdMap)) {
        state.leadId = get(state.applicationToLeadIdMap, applicationId, null);
      }
    }
  },
};
