<template>
  <div class="Notification">
    <div class="boxWrapper">
      <transition name="slide" v-for="(message, index) in data" :key="index">
        <div class="boxRow" :key="index" v-if="message != null">
          <div class="box">
            <div class="row">
              <div class="row">
                <div class="icon" :class="getIconColor(index)">
                  <!-- <a-icon :type="getIcon(index)" theme="filled" /> -->
                  <component :is="getIcon(index)"></component>
                </div>
                <div class="text">{{ message.message || '' }}</div>
              </div>
              <div class="closeWrapper">
                <!-- <a-icon type="close" @click="onClose(index)" /> -->
                <close-circle-filled
                  @click="onClose(index)"
                ></close-circle-filled>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
} from '@ant-design/icons-vue';
import { NotificationObj } from '@/interfaces/component_data';
import eventBus from '@/lib/event_bus';
import { isEmpty } from '@/lib/helpers';

const props = withDefaults(
  defineProps<{
    dissmisable?: boolean;
    data: NotificationObj[];
  }>(),
  {
    dissmisable: true,
  }
);

const timer = ref<any[]>([]);

onMounted(() => {
  if (props.data && props.data.length > 0) {
    const tempTimerArray: any = [];
    props.data.forEach((message, index) => {
      const duration: number | undefined = message.duration || undefined;
      const timer = setTimeout(() => {
        onClose(index);
      }, duration || 5000);
      tempTimerArray.push(timer);
    });
    timer.value = tempTimerArray;
  }
});

function onDataChanged(newList: any[], oldList: any[]) {
  if (!isEmpty(newList)) {
    if (newList.length !== oldList.length) {
      const tempTimerArray = timer.value;
      for (
        let index = newList.length - 1;
        index > oldList.length - 1;
        index--
      ) {
        const duration: number | undefined =
          props.data[index].duration || undefined;
        const timer = setTimeout(() => {
          onClose(index);
        }, duration || 5000);
        tempTimerArray.push(timer);
      }
      timer.value = tempTimerArray;
    }
  }
}

function getIcon(index: number) {
  const type: string | undefined = props.data[index].type;
  if (!type) {
    return InfoCircleFilled;
  }
  switch (type) {
    case 'error':
      return CloseCircleFilled;
    case 'success':
      return CheckCircleFilled;
    case 'warning':
      return ExclamationCircleFilled;
    case 'info':
      return InfoCircleFilled;
    default:
      return InfoCircleFilled;
  }
}

function getIconColor(index: number) {
  const classes: any = {
    error: false,
    success: false,
    warning: false,
  };
  const type: string | undefined = props.data[index].type;
  if (!type) {
    return {};
  }
  switch (type) {
    case 'error':
      classes.error = true;
      return classes;
    case 'success':
      classes.success = true;
      return classes;
    case 'warning':
      classes.warning = true;
      return classes;
    case 'info':
      classes.info = true;
      return classes;
    default:
      return {};
  }
}

function onClose(index: number) {
  destroyTimer(timer.value[index]);
  eventBus.emit('remove-message', { index });
  if (props.data && props.data.every((value) => value === null)) {
    closeNotifications();
  }
}

function closeNotifications() {
  timer.value = [];
  eventBus.emit('toggle-notification', { show: false });
}

function destroyTimer(timer: any) {
  if (timer != null) {
    clearTimeout(timer);
  }
}

watch(props.data, onDataChanged);

onBeforeUnmount(() => {
  if (timer.value && timer.value.length > 0) {
    timer.value.forEach((timer: any) => {
      destroyTimer(timer);
    });
  }
});
</script>
<style lang="scss">
.Notification {
  display: flex;
  pointer-events: none;
  justify-content: flex-end;
  padding: 38px 22px;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 1000;

  .boxRow {
    display: flex;
    background: transparent;
    justify-content: flex-end;
  }

  .row {
    display: flex;
  }

  p {
    font-size: var(--font_md);
    margin: 0;
    font-weight: bold;
  }

  .box {
    display: table;
    justify-content: space-between;
    padding: 10px 16px;
    margin: 0px 0px 10px 0px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    pointer-events: all;

    .icon {
      margin-right: 8px;

      &.error {
        color: #f5222d;
      }

      &.warning {
        color: #faad14;
      }

      &.success {
        color: #52c41a;
      }

      &.info {
        color: #1890ff;
      }
    }

    .text {
      color: rgba(0, 0, 0, 0.65);
      font-size: var(--font-md);
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum';
    }

    .closeWrapper {
      margin-left: 16px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
