<template>
  <svg width="23px" height="23px" viewBox="0 0 23 23" class="search-icon">
    <g
      class="VNTaskDetails"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="TasksDetails/CustomerDetails"
        transform="translate(-28.000000, -311.000000)"
      >
        <g class="Group">
          <g class="ic_search" transform="translate(25.000000, 308.000000)">
            <g class="Icon-24px">
              <path
                d="M19.375,17.5 L18.3875,17.5 L18.0375,17.1625 C19.2625,15.7375 20,13.8875 20,11.875 C20,7.3875 16.3625,3.75 11.875,3.75 C7.3875,3.75 3.75,7.3875 3.75,11.875 C3.75,16.3625 7.3875,20 11.875,20 C13.8875,20 15.7375,19.2625 17.1625,18.0375 L17.5,18.3875 L17.5,19.375 L23.75,25.6125 L25.6125,23.75 L19.375,17.5 L19.375,17.5 Z M11.875,17.5 C8.7625,17.5 6.25,14.9875 6.25,11.875 C6.25,8.7625 8.7625,6.25 11.875,6.25 C14.9875,6.25 17.5,8.7625 17.5,11.875 C17.5,14.9875 14.9875,17.5 11.875,17.5 L11.875,17.5 Z"
                class="color"
                fill="#000000"
              ></path>
              <polygon class="Shape" points="0 0 30 0 30 30 0 30"></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.search-icon {
  --icon-color: #363636;
  .color {
    fill: var(--icon-color);
  }
}
</style>
